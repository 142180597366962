import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LocationFilter from '../../component/filters/LocationFilter';
import SearchFilter from '../../component/filters/SearchFilter';
import VehicleFilter from '../../component/filters/VehicleFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryLocationVisitSummaryMapped } from '../../redux/slices/locationVisits/locationVisitsInterface';
import { getLocationVisits, getLocationVisitsById, getLocationVisitsFetching } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, ChipLocation } from '../../styled/components';
import { LocationVisitsProps } from './interface';

const SpanStyled = styled.span`
  &.red {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

export const LocationVisits: React.FC<LocationVisitsProps> = ({ withFilters }: LocationVisitsProps) => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const { id } = useParams();
  const [params, setURLParams] = useSearchParams();
  const paramsToUse = useMemo(
    () => ({
      ...(id ? { warehouseId: id } : {}),
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
    }),
    [params, id],
  );
  const abilities = useSelector(getUserAbilities);
  const locations = useSelector(getLocationVisitsById(paramsToUse));
  const fetching = useSelector(getLocationVisitsFetching);

  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: locations.pagination });
  const locationsExport = useSelector(getLocationVisitsById({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(
      getLocationVisits({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      getLocationVisits({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const PeriodFilter = usePeriodFilter({ params, setURLParams });

  const columns = useMemo(
    () => [
      {
        Header: 'Site Visit',
        accessor: 'periodMapped',
        sortBy: 'first_visit',
        width: '30%',
        minWidth: 200,
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped & { periodMapped: string } } }) => (
          <ChipLocation
            label={<Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>{row.original.periodMapped}</Link>}
            size="small"
            variant="outlined"
            status={row.original.tools_left_behind_cnt && row.original.tools_left_behind_cnt > 0 ? 'left behind' : 'transparent'}
          />
        ),
        ...(withFilters && { Filter: SearchFilter, filterOrder: -1 }),
      },
      {
        Header: 'Vehicle',
        accessor: 'vehicle_name',
        width: 70,
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) => (
          <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
        ),
        ...(withFilters && { Filter: PeriodFilter }),
      },

      {
        Header: 'Customer & Location',
        accessor: 'customerLocationMapped',
        sortBy: 'customer_name',
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) =>
          row.original.site_id ? (
            <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.customerLocationMapped}</Link>
          ) : (
            row.original.customerLocationMapped
          ),
        ...(withFilters && { Filter: VehicleFilter }),
        ellipsis: true,
        width: '30%',
      },
      {
        Header: 'Used',
        accessor: 'tools_used_cnt',
        align: 'right',
        width: 50,
        ...(withFilters && { Filter: LocationFilter, filterOrder: -1 }),
      },
      {
        Header: 'Left Behind',
        accessor: 'tools_left_behind_cnt',
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) => (
          <SpanStyled className={`${row.original.tools_left_behind_cnt && row.original.tools_left_behind_cnt > 0 && 'red'}`}>
            {row.original.tools_left_behind_cnt}
          </SpanStyled>
        ),
        align: 'right',
        width: 50,
      },
      ...(abilities.can('Stock')
        ? [
            {
              Header: 'Installed',
              accessor: 'stock_installed_cnt',
              align: 'right',
              width: 50,
            },
          ]
        : []),
      ...(!abilities.can('Container')
        ? [
            {
              Header: 'Recovered',
              accessor: 'tools_recovered_cnt',
              align: 'right',
              width: 50,
            },
          ]
        : []),
    ],
    [withFilters, PeriodFilter, abilities],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={locations.result}
          dataExport={locationsExport?.result}
          loading={fetching}
          {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
          paginationControl={paginationControl}
          pagination={pagination}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
    </>
  );
};
