import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scanEventsClient } from '../../../api/client';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { ScanEventsMappedType, ScanEventsQuery, ScanEventsState, ScanEventsType } from './scanEventsInterface';

export const scanEventsListAdapter = createEntityAdapter<ScanEventsType>({
  selectId: (scan) => scan.id,
});

export const getScanEvents = createAsyncThunk('scanEvents/getScanEvents', async (query: ScanEventsQuery) => {
  const argumentsKeys = [
    'locationVisitId',
    'payloadId',
    'deviceId',
    'warehouseId',
    'vehicleId',
    'siteId',
    'workingSetId',
    'processedBegin',
    'processedEnd',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];

  return scanEventsClient.getScanEvents.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      locationVisitId?: string,
      payloadId?: string,
      deviceId?: string,
      warehouseId?: string,
      vehicleId?: string,
      siteId?: string,
      workingSetId?: string,
      processedBegin?: string,
      processedEnd?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const scanEventsSlice = createSlice({
  name: 'scanEvents',
  initialState: {
    events: scanEventsListAdapter.getInitialState(),
  } as ScanEventsState,
  reducers: {
    setFetching: (state, action: PayloadAction<{ key: string; fetching: boolean }>) => {
      state.fetching = { ...state.fetching, [action.payload.key]: action.payload.fetching };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScanEvents.pending, (state: ScanEventsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getScanEvents: true };
    });
    builder.addCase(getScanEvents.fulfilled, (state: ScanEventsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getScanEvents: false };
      if (action.payload.data.result) {
        scanEventsListAdapter.upsertOne(state.events, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getScanEvents.rejected, (state: ScanEventsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getScanEvents: false };
    });
  },
});

export const { selectById: selectScanEventsById } = scanEventsListAdapter.getSelectors((state: RootState) => state.scanEvents.events);

const getScanEventsByIdSelector = createSelector(
  [selectScanEventsById],
  (scanEvents): ScanEventsMappedType => ({
    pagination: getPagination(scanEvents),
    result: scanEvents?.result?.map((event) => ({
      ...event,
      payloadIdMapped: event.payload_id?.split('-').slice(-1),
      productIdentifierMapped: `${event.product_name}${event.product_name && event.identifier && ' — '}${event.identifier}`,
    })),
  }),
);

export const getScanEventsById =
  (keys: ScanEventsQuery) =>
  (state: RootState): ScanEventsMappedType | undefined =>
    getScanEventsByIdSelector(state, keysToId(keys));

export const getAllScanEvents = (state: RootState): ScanEventsMappedType | undefined => getScanEventsByIdSelector(state, keysToId());

export const getScanEventsFetching = (state: RootState): boolean => state.scanEvents.fetching?.getScanEvents !== false;

export const { setFetching } = scanEventsSlice.actions;

export default scanEventsSlice.reducer;
