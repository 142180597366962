import { EntityState, SerializedError } from '@reduxjs/toolkit';
import { QueryLocationVisit, QueryLocationVisitItem, QueryLocationVisitSummary } from '../../../api/schema';
import { ListResponseType, PaginationType } from '../../interface';

export type LocationVisitSummary = {
  id: string;
  pagination?: PaginationType;
  result?: QueryLocationVisitSummary[];
};
export type LocationVisitItems = {
  id: string;
  pagination?: PaginationType;
  result?: QueryLocationVisitItem[];
};

export type LocationVisitsState = {
  fetching?: {
    [key: string]: boolean;
  };
  locationVisitsDetails: EntityState<QueryLocationVisit>;
  locationVisitsById: EntityState<LocationVisitSummary>;
  locationVisitItems: EntityState<LocationVisitItems>;
  error?: SerializedError;
};

export type LocationsVisitsQuery = {
  warehouseId?: string;
  vehicleId?: string;
  siteId?: string;
  workingSetId?: string;
  itemId?: string;
  productId?: string;
  siteType?: string;
  processedBegin?: string;
  processedEnd?: string;
  search?: string;
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: {
    [key: string]: string | number | boolean;
  };
};

export type GetLocationVisitItemsQuery = {
  usageType?: string;
  processedBegin?: string;
  processedEnd?: string;
  productTypeId?: string;
  vehicleId?: string;
  siteId?: string;
  manufacturerId?: string;
  sortBy?: string;
  offset?: number;
  limit?: number;
  options?: {
    [key: string]: string | number | boolean;
  };
};

export type QueryLocationVisitItemMapped = QueryLocationVisitItem & {
  productIdentifier: string;
  identifierProductMapped: string;
  lastSeenLocationMapped: string;
  usageMapped: string;
};

export enum UsageEnum {
  'left behind' = 'Left Behind',
  'recovered' = 'Recovered',
  'usage' = 'Usage',
  'installed' = 'Installed',
}
export type QueryLocationVisitMapped = Omit<QueryLocationVisit, 'items'> & {
  items?: QueryLocationVisitItemMapped[];
};

export type QueryLocationVisitSummaryMapped = QueryLocationVisitSummary & {
  periodMapped: string;
  customerNameMapped: string;
  customerLocationMapped: string;
};

export type QueryLocationVisitItemsMapped = QueryLocationVisitItem & {
  periodMapped: string;
  customerNameMapped: string;
  customerSiteNameMapped: string;
  productIdentifierMapped: string;
  sinceMapped: string;
};

export type LocationVisitsToolsType = ListResponseType & {
  id?: string;
  pagination?: PaginationType;
  result?: QueryLocationVisitItemsMapped[];
};

export type LocationVisitsSummaryType = ListResponseType & {
  id?: string;
  pagination?: PaginationType;
  result?: QueryLocationVisitMapped[];
};
