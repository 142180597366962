import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../component/table/Table';
import { dashboardAlertEvents, getDashboardAlertEvents, getDashboardAlertEventsFetching } from '../../redux/slices/dashboard/dashboardSlice';
import { useDispatch } from '../../redux/store';

export const DashboardAlertEvent: React.FC = () => {
  const dispatch = useDispatch();

  const alertEvents = useSelector(getDashboardAlertEvents);
  const fetching = useSelector(getDashboardAlertEventsFetching);

  useEffect(() => {
    dispatch(dashboardAlertEvents());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'createdMapped',
        ellipsis: true,
        width: 150,
      },
      {
        Header: 'Message',
        accessor: 'message',
        ellipsis: true,
      },
    ],
    [],
  );

  const getRowProps = useCallback(
    (row) => ({
      link: row.original.object_type === 'Item' ? `/tools/items/${row.original.target_id}/details` : `/company/vehicles/${row.original.target_id}/details`,
    }),
    [],
  );

  return (
    <Table
      size="small"
      toolbarDense="medium"
      title="Vehicle Activity"
      columns={columns}
      data={alertEvents}
      loading={fetching}
      getRowProps={getRowProps}
      defaultPageSize={10}
    />
  );
};
