import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToolsList } from '../../component/tools/ToolsList';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductToolsById, listProductTools } from '../../redux/slices/tools/toolsSlice';
import { useDispatch } from '../../redux/store';

type OwnerEventsProps = {
  withAssignedTo?: boolean;
};
export const OwnerEvents: React.FC<OwnerEventsProps> = ({ withAssignedTo = true }: OwnerEventsProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params] = useSearchParams();
  const productTypeId = params.productTypeId || '1,3,4,5,6';
  const ownerEventsParams = useMemo(() => ({ ownerId: id, productTypeId }), [id, productTypeId]);
  const ownerEvents = useSelector(getProductToolsById(ownerEventsParams));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: ownerEvents.pagination });

  useEffect(() => {
    if (id) {
      dispatch(listProductTools({ ownerId: id, productTypeId, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet, productTypeId]);

  return (
    <ToolsList items={ownerEvents} paginationControl={paginationControl} pagination={pagination} withAssignedTo={withAssignedTo} withProduct withManufacturer />
  );
};
