import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { DateTime } from 'luxon';
import { Activity, FileText, HelpCircle, Home, Package, Tool, Truck, User } from 'react-feather';
import { SidebarItemsType } from '../../types/sidebar';

const tagsSection = [
  {
    href: '/activity/dashboard/summary',
    icon: Activity,
    title: 'Activity',
    hideSubmenu: true,
    children: [
      {
        href: '/activity/dashboard/summary',
        hrefAlias: ['/'],
        title: 'Dashboard',
      },
      {
        href: `/activity/site-visits?siteType=customer&processedBegin=${DateTime.local().startOf('day').toFormat('yyyy-MM-dd')}`,
        hrefAlias: ['/activity/site-visits/:id/details', '/activity/site-visits/:id/details/:tab'],
        title: 'Site Visits',
      },
      {
        href: '/activity/tool-usage?activityBegin=monday&activityEnd=today',
        title: 'Container Activity',
        can: ['Container'],
      },
      {
        href: '/activity/tool-usage?processedBegin=monday&processedEnd=today',
        title: 'Tool Usage',
        canNot: ['Container'],
      },
      {
        href: '/activity/stock-installed?processedBegin=today',
        title: 'Stock Installed',
        can: ['Stock'],
      },
      {
        href: '/activity/vehicle-tracking',
        title: 'Vehicle Tracking',
        can: ['Vehicle Tracking'],
      },
      {
        href: '/activity/fleet-tracking',
        title: 'Fleet Tracking',
        can: ['Fleet Tracking'],
      },
      {
        href: '/activity/map',
        title: 'Map',
      },
    ],
  },
  {
    href: '/tools/dashboard',
    icon: Tool,
    title: 'Tools',
    hideSubmenu: true,
    children: [
      {
        href: '/tools/dashboard',
        title: 'Dashboard',
      },
      {
        href: '/tools/items',
        hrefAlias: ['/tools/items/:id/details', '/tools/items/:id/details/:tab'],
        title: 'Items',
      },
      {
        href: '/tools/products',
        hrefAlias: ['/tools/products/:id/details', '/tools/products/:id/details/:tab'],
        title: 'Products',
      },
      {
        href: '/tools/manufacturers',
        hrefAlias: ['/tools/manufacturers/:id/details', '/tools/manufacturers/:id/details/:tab'],
        title: 'Manufacturers',
        canNot: ['Container'],
      },
      {
        href: '/tools/reports',
        title: 'Reports',
      },
    ],
  },
  {
    href: '/stock/dashboard',
    icon: Package,
    title: 'Stock',
    hideSubmenu: true,
    can: ['Stock'],
    children: [
      {
        href: '/stock/dashboard',
        title: 'Dashboard',
      },
      {
        href: '/stock/items',
        title: 'Items',
        hrefAlias: ['/stock/items/:id/details'],
      },
      {
        href: '/stock/products',
        hrefAlias: ['/stock/products/:id/details', '/stock/products/:id/details/:tab'],
        title: 'Products',
      },
      {
        href: '/stock/manufacturers',
        hrefAlias: ['/stock/manufacturers/:id/details', '/stock/manufacturers/:id/details/:tab'],
        title: 'Manufacturers',
      },
    ],
  },
  {
    href: '/events/alert-events?processedBegin=today',
    icon: FeedbackOutlinedIcon,
    title: 'Events',
    hideSubmenu: true,
    can: ['events'],
    children: [
      {
        href: '/events/alert-events?processedBegin=today',
        title: 'Alert Events',
      },
      {
        href: '/events/maintenance',
        title: 'Maintenance Events',
      },
      {
        href: '/events/tag-health',
        title: 'Tag Health',
      },
    ],
  },
  {
    href: '/customers/job-sites',
    icon: User,
    title: 'Customers',
    hideSubmenu: true,
    children: [
      {
        href: '/customers/job-sites',
        hrefAlias: ['/customers/job-sites/:id/details'],
        title: 'Job Sites',
      },
      {
        href: '/customers/customers',
        hrefAlias: ['/customers/customers/:id/details'],
        title: 'Customers',
      },
      {
        href: '/customers/open-job-sites',
        title: 'Open Job Sites',
      },
    ],
  },
  {
    href: '/company/vehicles',
    icon: Home,
    title: 'Your Company',
    hideSubmenu: true,
    children: [
      {
        href: '/company/vehicles',
        hrefAlias: ['/company/vehicles/:id/details', '/company/vehicles/:id/details/:tab'],
        title: 'Fleet Vehicles',
      },
      {
        href: '/company/users',
        hrefAlias: ['/company/users/:id/details', '/company/users/current', '/company/users/current/change-password'],
        title: 'Users',
        can: ['users'],
      },
      {
        href: '/company/sites',
        hrefAlias: ['/company/sites/:id/details', '/company/sites/:id/details/:tab'],
        title: 'Company Sites',
      },
      {
        href: '/company/rooms',
        hrefAlias: ['/company/rooms/:id/details'],
        title: 'Rooms',
      },
    ],
  },
  {
    href: '/delivery/prepared',
    icon: Truck,
    title: 'Delivery',
    can: ['Forced Delivery'],
    hideSubmenu: true,
    children: [
      {
        href: '/delivery/prepared',
        title: 'Prepared',
      },
      {
        href: '/delivery/delivered',
        title: 'Delivered',
      },
      {
        href: '/delivery/reports',
        title: 'Reports',
      },
      {
        href: '/delivery/closed-job-site',
        title: 'Closed Job Site',
      },
    ],
  },
  {
    href: '/reporting',
    icon: FileText,
    title: 'Reporting',
    hideSubmenu: true,
    children: [
      {
        href: '/reporting/reports',
        title: 'Reports',
      },
      {
        href: '/reporting/batch',
        title: 'Batch',
      },
    ],
  },
  {
    href: '',
    title: null,
  },

  {
    href: '/configuration',
    icon: SettingsOutlinedIcon,
    title: 'Configuration',
    can: ['configuration'],
  },
  {
    href: '/help',
    icon: HelpCircle,
    title: 'Help',
  },
] as SidebarItemsType[];

const navItems = [
  {
    pages: tagsSection,
  },
];

export default navItems;
