import { Card, CardHeader, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import { QueryVehicle } from '../../api/schema';
import { DatePicker } from '../../component/datePicker/DatePicker';
import useSearchParams from '../../hooks/useSearchParams';

type IProp = {
  defaultProcessedBegin: DateTime;
  vehicles?: QueryVehicle[];
  title?: string;
};

export const TrackingForm = ({ defaultProcessedBegin, vehicles, title }: IProp) => {
  const [params, setURLParams] = useSearchParams();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const slotProps = {
    popper: {
      disablePortal: true,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -170],
          },
        },
      ],
      sx: {
        '& .MuiPaper-root': {
          boxShadow: (theme) => `${theme.shadows[1]} !important`,
        },
      },
    },
  };

  return (
    <Card sx={{ p: 2, overflow: 'visible' }}>
      {title && <CardHeader title={title} sx={{ p: 0 }} />}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={4}>
          <FormControlLabel
            control={
              <Switch
                checked={params.connectMarkers !== 'false'}
                onChange={(event) => setURLParams({ replace: false, newParams: { connectMarkers: event.target.checked } })}
                size={isMdUp ? 'medium' : 'small'}
              />
            }
            label="Connect Locations"
          />
          <FormControlLabel
            control={
              <Switch
                checked={params.captions === 'true'}
                onChange={(event) => setURLParams({ replace: false, newParams: { captions: event.target.checked } })}
                size={isMdUp ? 'medium' : 'small'}
                sx={{ mt: 1 }}
              />
            }
            label="Show Captions"
            sx={{ mt: '0 !important' }}
          />
          <FormControl fullWidth>
            <InputLabel id="vehicles_label">Vehicles</InputLabel>
            <Select
              labelId="vehicles_label"
              value={params.vehicleId ?? ''}
              label="Vehicles"
              onChange={(event) => {
                setURLParams({ replace: false, newParams: { vehicleId: event.target.value, zoom: '', lng: '', lat: '' } });
              }}
              MenuProps={{
                disablePortal: true,
              }}
              sx={{
                '& .MuiPaper-root': {
                  boxShadow: (theme) => `${theme.shadows[1]} !important`,
                },
              }}
              size={isMdUp ? 'medium' : 'small'}
            >
              {vehicles?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ position: 'relative' }}>
            <DatePicker
              type="date"
              label="From"
              withClear
              value={params.processedBegin ? DateTime.fromISO(params.processedBegin).toJSDate() : defaultProcessedBegin.toJSDate()}
              onChange={(newValue: Date | null | string) => {
                const valueToSend = typeof newValue === 'string' ? null : newValue;
                setURLParams({
                  replace: false,
                  newParams: {
                    processedBegin: valueToSend ? DateTime.fromJSDate(valueToSend).toISO() : '',
                    zoom: '',
                    lng: '',
                    lat: '',
                  },
                });
              }}
              inputFormat="MM/dd/yy"
              ampm={false}
              slotProps={slotProps}
              InputProps={{
                size: isMdUp ? 'medium' : 'small',
              }}
            />
          </FormControl>
          {isMdUp && (
            <FormControl fullWidth>
              <InputLabel id="time_window">Time window</InputLabel>
              <Select
                labelId="time_window"
                value={params.timeWindow ?? '1'}
                label="Time window"
                onChange={(event) => {
                  setURLParams({ replace: false, newParams: { timeWindow: event.target.value, zoom: '', lng: '', lat: '' } });
                }}
                MenuProps={{
                  disablePortal: true,
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: (theme) => `${theme.shadows[1]} !important`,
                  },
                }}
                size={isMdUp ? 'medium' : 'small'}
              >
                {Array.from({ length: 7 }, (_, index) => index + 1).map((option) => (
                  <MenuItem key={option} value={option.toString()}>
                    {option} {option === 1 ? 'day' : 'days'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </LocalizationProvider>
    </Card>
  );
};
