import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AlertEventsFilter from '../../component/filters/AlertEventsFilter';
import VehicleFilter from '../../component/filters/VehicleFilter';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  alertEventsList,
  getAlertEventsById,
  getAlertEventsFetching,
  getAlertEventsTypes,
  getAllAlertEventsTypes,
  getScanEventsFetching,
} from '../../redux/slices/alertEvents/alertEventsSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex } from '../../styled/components';

export const AlertEvents: React.FC = () => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params, setURLParams] = useSearchParams();
  const paramsToUse = useMemo(
    () => Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
    [params],
  );

  const alertEvents = useSelector(getAlertEventsById(paramsToUse));
  const fetching = useSelector(getAlertEventsFetching);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: alertEvents?.pagination });
  const alertEventsExport = useSelector(getAlertEventsById({ ...paramsToUse, ...paginationToGetAll }));
  const alertEventsTypes = useSelector(getAllAlertEventsTypes);
  const fetchingTypes = useSelector(getScanEventsFetching);
  const [popupOpen, setPopupOpen] = useState('');

  useEffect(() => {
    dispatch(
      alertEventsList({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      alertEventsList({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  useEffect(() => {
    dispatch(getAlertEventsTypes());
  }, [dispatch]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const PeriodFilter = usePeriodFilter({ params, setURLParams, size: 'small' });

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'createdMapped',
        sortBy: 'processed_at',
        ellipsis: true,
        Filter: PeriodFilter,
      },
      {
        Header: 'Event Type',
        accessor: 'event_type_name',
        ellipsis: true,
      },

      {
        Header: 'Target',
        accessor: 'object_type',
        ellipsis: true,
        Filter: VehicleFilter,
        filterProps: {
          paramName: 'vehicleName',
          value: 'name',
        },
      },
      {
        Header: 'Message',
        accessor: 'message',
        ellipsis: true,
        width: '40%',
        Filter: AlertEventsFilter,
        filterProps: {
          alertEvents: alertEventsTypes,
          fetching: fetchingTypes,
        },
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          ),
      },
    ],
    [PeriodFilter, alertEventsTypes, fetchingTypes],
  );

  const getRowProps = useCallback(
    (row) => ({
      link: row.original.object_type === 'Item' ? `/tools/items/${row.original.target_id}/details` : `/company/vehicles/${row.original.target_id}/details`,
    }),
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={alertEvents?.result}
          dataExport={alertEventsExport?.result}
          loading={fetching}
          showDefaultFilters
          onFiltersClear={handleFiltersClear}
          paginationControl={paginationControl}
          pagination={pagination}
          headerAddon={
            <IconButton onClick={() => setPopupOpen('alert')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          getRowProps={getRowProps}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
      <InfoPopup
        title="Alert Events"
        subtitle="Displays a list of Alert Events created during the time period of filters. Clicking on a row will navigate to the affected Tool or Vehicle.  Note only “Item - Left Behind” events are sent via SMS."
        onClose={() => setPopupOpen('')}
        open={popupOpen === 'alert'}
        maxWidth="sm"
      >
        <CardFlex>
          <CardHeader
            title={
              <>
                <Typography variant="h4" component="div">
                  Alert Event Types
                </Typography>
                <ul style={{ paddingLeft: 16 }}>
                  <li>Set - Created → Vehicle begins tracking tools</li>
                  <li>Set - Carryover → Vehicle begins tracking tools with tools carried over from previous Working Set</li>
                  <li>Set - Closed → Vehicle stops tracking tools</li>
                  <li>Set - Unresolved → Vehicle stops tracking tools with Tools left behind</li>
                  <li>Visit - Arrived → Vehicle arrives at a Job Site</li>
                  <li>Visit - Departed → Vehicle departs a Job Site</li>
                  <li>Tool - Left Behind → Tools left at Job Site</li>
                  <li>Tool - Missing → Tools went missing in Vehicle</li>
                  <li>Tool - Handoff → Tools transferred between Vehicles</li>
                  <li>Tool - Maintenance → Tools maintenance date reached</li>
                </ul>
              </>
            }
          />
        </CardFlex>
      </InfoPopup>
    </>
  );
};
