import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { isEmpty } from 'lodash';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { epcClient, imageBase } from '../../api/client';
import { QueryAssignmentItem } from '../../api/schema';
import toastOptions from '../../helpers/toastOptions';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import {
  createAssignment,
  deliverAssignment,
  getAssignment,
  getAssignmentById,
  lookupAssignmentItem,
  updateAssignment,
} from '../../redux/slices/assignments/assignmentsSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';
import QrScan from '../qrScan/QrScan';
import Table from '../table/Table';

interface IProp {
  siteAssignmentId?: string;
  onClose: (redirectURL?: string, id?: string) => void;
  open: boolean;
  jobSiteId?: string;
  siteName?: string;
}

interface Values {
  identifier: string;
}

const schema = yup.object().shape({
  identifier: yup.string().required('Required field'),
});

export const AssignmentForm = ({ onClose, siteAssignmentId, open, jobSiteId, siteName }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const assignment = useSelector(getAssignment(siteAssignmentId));
  const formikRef = useRef<FormikProps<Values>>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [modals, toggleModal] = useBooleanKeys({ showQrDialog: false });
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (siteAssignmentId) {
      dispatch(getAssignmentById(siteAssignmentId));
    }
  }, [dispatch, siteAssignmentId]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);

      let assignmentId = assignment?.id;

      if (!assignmentId && jobSiteId) {
        const result = await dispatch(createAssignment({ job_site_id: jobSiteId })).unwrap();
        assignmentId = result.data.result?.id;
      }
      if (assignmentId) {
        const identifier = values.identifier
          .split('/')
          .reverse()[0]
          .replace(/^0[xX]/, '');
        formikRef?.current?.setFieldValue('identifier', identifier);
        await dispatch(lookupAssignmentItem({ assignmentId, identifier })).unwrap();
        dispatch(getAssignmentById(assignmentId));
        formikRef?.current?.setFieldValue('identifier', '', false);
        inputRef.current?.focus();
        setTimeout(() => {
          if (itemsRef.current && contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
            itemsRef.current.scrollIntoView();
          }
        }, 500);
      }
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
      formikRef?.current?.setFieldValue('identifier', '', false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );
  const handleRemoveItem = useCallback(
    async (event: SyntheticEvent<HTMLButtonElement>) => {
      const { dataset } = event.currentTarget as HTMLButtonElement;
      if (dataset.id && assignment?.id) {
        try {
          await dispatch(
            updateAssignment({
              deleted_items: [dataset.id],
              id: assignment?.id,
            }),
          ).unwrap();

          dispatch(getAssignmentById(assignment?.id)).unwrap();
        } catch (error) {
          console.log(error); // eslint-disable-line
        }
      }
    },
    [assignment?.id, dispatch],
  );

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryAssignmentItem } }) =>
                row.original.image_key && (
                  <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2 },
            },
          ]
        : []),
      {
        Header: 'Identifier',
        accessor: 'identifier',
      },
      {
        Header: 'Product',
        accessor: 'product_name',
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer_name',
      },
      {
        Header: 'Remove',
        accessor: 'id',
        Cell: ({ row }: { row: { original: QueryAssignmentItem } }) => (
          <IconButton data-id={row.original.item_id} onClick={handleRemoveItem} color="primary">
            <CloseOutlinedIcon />
          </IconButton>
        ),
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
      },
    ],
    [handleRemoveItem, isSmUp],
  );

  const handleQrScanOpen = () => {
    toggleModal('showQrDialog');
    if (formikRef.current) {
      formikRef?.current?.setFieldValue('identifier', '');
    }
  };

  const handleQrScanClose = () => {
    toggleModal('showQrDialog');
  };

  const checkScanResult = async (decodedText) => {
    try {
      const response = await epcClient.validateEpc(decodedText);
      if (response.data.result || decodedText.length < 12) {
        formikRef?.current?.setFieldValue('identifier', decodedText);
      } else {
        toast.error(`"${decodedText}" is Invalid EPC.`, toastOptions);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const handleDeliver = async () => {
    if (assignment?.id) {
      try {
        const result = await dispatch(deliverAssignment(assignment?.id)).unwrap();
        navigate(`/activity/site-visits/${result.data.result}/details`);
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          identifier: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
          // setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3" component="div">
                  Assign Equipment For Delivery
                </Typography>

                <IconButton onClick={handleClose}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent ref={contentRef} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 260px)' }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" component="div" sx={{ mb: 5 }}>
                  Count: {assignment?.items?.length ?? 0} Items
                </Typography>
                <Box component="span">
                  <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={!assignment?.items}>
                    Deliver
                  </Button>
                </Box>
              </Stack>
              {!isEmpty(assignment?.items) ? (
                <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                  <Table columns={columns} data={assignment?.items || []} noGlobalFilter withPagination={false} />
                  <Box ref={itemsRef} />
                </Box>
              ) : (
                <Typography variant="subtitle1" component="div" sx={{ mb: 5 }}>
                  No assigned items present
                </Typography>
              )}
            </DialogContent>

            <DialogActions sx={{ px: 6, pb: 6, display: 'block' }}>
              <Stack direction="row" gap={4} flexWrap="nowrap" sx={{ mx: 4, my: 2 }}>
                <Typography sx={{ whiteSpace: 'nowrap', mt: 4 }}>Add Item</Typography>
                <Box component="div" flex="auto">
                  <TextField
                    autoFocus
                    inputRef={inputRef}
                    name="identifier"
                    label=""
                    value={values.identifier}
                    error={Boolean(touched.identifier && errors.identifier)}
                    fullWidth
                    helperText={touched.identifier && errors.identifier}
                    onBlur={handleBlur}
                    variant="outlined"
                    my={2}
                    onChange={handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleQrScanOpen} edge="end">
                            <Box component="i" className="tags-icon-barcode" sx={{ lineHeight: 1 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box component="div">
                  <Button type="submit" color="warning" variant="contained" disabled={isSubmitting} sx={{ mt: 2 }}>
                    Add
                  </Button>
                </Box>
              </Stack>
            </DialogActions>
          </form>
        )}
      </Formik>
      <QrScan open={modals.showQrDialog} onClose={handleQrScanClose} checkScanResult={checkScanResult} />
      <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
        <DialogTitle id="alert-dialog-title">Deliver</DialogTitle>
        <DialogContent>
          <Typography variant="h2" component="div">
            Are you sure you want to deliver these item(s) to {siteName}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)} color="primary" disabled={false}>
            Cancel
          </Button>
          <Button onClick={handleDeliver} color="primary" variant="contained" disabled={false}>
            Deliver
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
