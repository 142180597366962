import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { payloadsClient } from '../../../api/client';
import { QueryLocationSummary } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  ByWarehouseType,
  ClientState,
  GetPayloadsLocationsQuery,
  GetPayloadsQuery,
  PayloadsLocationsType,
  PayloadsType,
  QueryPayloadSummaryType,
  VehicleLocationsType,
} from './payloadsInterface';

export const payloadDetailsAdapter = createEntityAdapter<QueryPayloadSummaryType>({
  selectId: (payload) => payload.id,
});
export const payloadsByWarehouseIdAdapter = createEntityAdapter<ByWarehouseType>({
  selectId: (warehouse) => warehouse.id,
});

export const payloadsLocationsAdapter = createEntityAdapter<PayloadsLocationsType>({
  selectId: (vehicle) => vehicle.id,
});

export const getPayloadByUuid = createAsyncThunk('payloads/getPayloadByUuid', async (payloadID: string) => payloadsClient.getPayloadByUuid(payloadID));
export const getPayloads = createAsyncThunk('payloads/getPayloads', async (query: GetPayloadsQuery) => {
  const argumentsKeys = [
    'ownerId',
    'deviceId',
    'warehouseId',
    'workingSetId',
    'locationVisitId',
    'processedBegin',
    'processedEnd',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];

  return payloadsClient.getPayloads.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      ownerId?: string,
      deviceId?: string,
      warehouseId?: string,
      workingSetId?: string,
      locationVisitId?: string,
      processedBegin?: string,
      processedEnd?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getVehicleLocations = createAsyncThunk('payloads/getVehicleLocations', async (query: GetPayloadsLocationsQuery) => {
  const argumentsKeys = ['vehicleId', 'processedBegin', 'processedEnd', 'options'];

  return payloadsClient.getVehicleLocations.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [vehicleId: string, processedBegin: string, processedEnd: string, options?: Record<string, unknown>],
  );
});

export const payloadsSlice = createSlice({
  name: 'payloads',
  initialState: {
    payloadDetails: payloadDetailsAdapter.getInitialState(),
    payloadsByWarehouse: payloadsByWarehouseIdAdapter.getInitialState(),
    payloadsLocations: payloadsLocationsAdapter.getInitialState(),
  } as ClientState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPayloadByUuid.pending, (state: ClientState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getPayloadByUuid: true };
    });
    builder.addCase(getPayloadByUuid.fulfilled, (state: ClientState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getPayloadByUuid: false };
      if (action.payload.data.result) {
        payloadDetailsAdapter.upsertOne(state.payloadDetails, { id: action.meta.arg, ...action.payload.data.result });
      }
    });
    builder.addCase(getPayloadByUuid.rejected, (state: ClientState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getPayloadByUuid: false };
    });

    builder.addCase(getPayloads.pending, (state: ClientState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getPayloads: true };
    });
    builder.addCase(getPayloads.fulfilled, (state: ClientState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getPayloads: false };
      if (action.payload.data.result) {
        payloadsByWarehouseIdAdapter.upsertOne(state.payloadsByWarehouse, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getPayloads.rejected, (state: ClientState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getPayloads: false };
    });
    builder.addCase(getVehicleLocations.pending, (state: ClientState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getVehicleLocations: true };
    });
    builder.addCase(getVehicleLocations.fulfilled, (state: ClientState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getVehicleLocations: false };
      if (action.payload.data.result) {
        payloadsLocationsAdapter.upsertOne(state.payloadsLocations, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(getVehicleLocations.rejected, (state: ClientState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getVehicleLocations: false };
    });
  },
});

export const { selectById: selectPayloadDetailsById } = payloadDetailsAdapter.getSelectors((state: RootState) => state.payloads.payloadDetails);
export const { selectById: selectPayloadsByWarehouseId } = payloadsByWarehouseIdAdapter.getSelectors((state: RootState) => state.payloads.payloadsByWarehouse);
export const { selectById: selectVehicleLocations, selectAll: selectVehicleLocationsAll } = payloadsLocationsAdapter.getSelectors(
  (state: RootState) => state.payloads.payloadsLocations,
);

export const getPayloadDetailsById =
  (payloadID = '') =>
  (state: RootState): QueryPayloadSummaryType | undefined =>
    selectPayloadDetailsById(state, payloadID);

const selectPayloadsByWarehouseIdSelector = createSelector([selectPayloadsByWarehouseId], (warehouse) => ({
  pagination: getPagination(warehouse),
  result: warehouse?.result?.map((payload) => ({ ...payload, xScanMapped: payload.is_cross_scan ? 'Yes' : 'No' })),
}));

export const getPayloadsByWarehouse =
  (keys: GetPayloadsQuery) =>
  (state: RootState): PayloadsType =>
    selectPayloadsByWarehouseIdSelector(state, keysToId(keys));

const selectVehicleLocationsSelector = createSelector([selectVehicleLocations], (locations) => ({
  pagination: getPagination(locations),
  result: locations?.result,
}));

export const getVehicleLocationsPath =
  (keys: GetPayloadsLocationsQuery) =>
  (state: RootState): VehicleLocationsType =>
    selectVehicleLocationsSelector(state, keysToId(keys));

const selectVehicleLocationsAllSelector = createSelector(
  [selectVehicleLocationsAll, (state, keys) => keys],
  (locations, keys) =>
    keys.vehicleId?.split(',').reduce(
      (partLocations: { [key: string]: QueryLocationSummary[] }, id: string) => {
        const targetId = keysToId({ ...keys, vehicleId: id });
        return { ...partLocations, [id]: locations.find((location) => targetId === location.id)?.result ?? [] };
      },
      {} as { [key: string]: QueryLocationSummary[] },
    ),
);

export const getVehicleLocationsPathAll =
  (keys: GetPayloadsLocationsQuery) =>
  (state: RootState): { [key: string]: QueryLocationSummary[] } =>
    selectVehicleLocationsAllSelector(state, keys);

export const getPayloadFetching = (state: RootState): boolean => state.payloads.fetching?.getPayloadByUuid !== false;
export const getPayloadByWarehouseIdFetching = (state: RootState): boolean => state.payloads.fetching?.getPayloads !== false;
export const getVehicleLocationsPathFetching = (state: RootState): boolean => state.payloads.fetching?.getVehicleLocations !== false;

export default payloadsSlice.reducer;
