import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToolForm } from '../../component/tools/ToolForm';
import { ToolsList } from '../../component/tools/ToolsList';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductToolsById, listProductTools, setLastFormData } from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';

export const Tools: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setURLParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const paramsToUse = useMemo(
    () => Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: params[key] }), { active: 'true', productTypeId: '1,3,4,6' }),
    [params],
  );
  const productTools = useSelector(getProductToolsById(paramsToUse));
  const [modals, toggleModal] = useBooleanKeys({ showAddProductItem: params.new !== undefined, showAddAnother: false });
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: productTools.pagination });
  const productToolsExport = useSelector(getProductToolsById({ ...paramsToUse, ...paginationToGetAll }));
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const handleCloseAddProductItem = (options?: { redirect?: string; edit?: boolean }) => {
    toggleModal('showAddProductItem', false);
    setURLParams({ new: undefined });

    if (options && !options?.edit) {
      toggleModal('showAddAnother', true);
      setRedirectTo(options.redirect);
      dispatch(
        listProductTools({
          ...paramsToUse,
          ...paginationToGet,
        }),
      );
    } else if (options?.redirect) {
      navigate(options.redirect);
    }
  };

  const handleAddAnother = () => {
    toggleModal('showAddAnother', false);
    toggleModal('showAddProductItem', true);
  };

  const handleCloseAnother = () => {
    toggleModal('showAddAnother', false);
    dispatch(setLastFormData(undefined));

    if (redirectTo) {
      navigate(redirectTo);
    }
  };

  return (
    <>
      <ToolsList
        items={productTools}
        itemsExport={productToolsExport}
        {...(abilities.can('create') && {
          headerAddon: (
            <Button color="primary" variant="contained" onClick={() => toggleModal('showAddProductItem', true)}>
              New
            </Button>
          ),
        })}
        withFilters
        paginationControl={paginationControl}
        pagination={pagination}
        withAssignedTo
        withActive
        withProduct
        withManufacturer
        getExportData={getExportData}
      />
      <ToolForm open={modals.showAddProductItem} onClose={handleCloseAddProductItem} type="tools" />
      <Dialog fullWidth maxWidth="xs" open={modals.showAddAnother} onClose={handleCloseAnother}>
        <DialogTitle id="alert-dialog-title">Add another?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseAnother} color="primary">
            No
          </Button>
          <Button onClick={handleAddAnother} color="success" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
