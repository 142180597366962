import {
  Button,
  Checkbox as CheckboxMui,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { InputHTMLAttributes, ReactElement, SyntheticEvent, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { getDeviceById, getDeviceConfiguration, getDeviceDetailsById, saveDeviceConfiguration } from '../../redux/slices/devices/devicesSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';

type CheckBoxInputProps = InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string;
};
interface IProp {
  onClose: (redirectURL?: string) => void;
  open: boolean;
}

interface Values {
  scan_duration?: number;
  active_sleep?: number;
  saving_sleep?: number;
  accelerometer_force?: number;
  antennas?: number;
  event_reporting?: number;
}

const schema = yup.object().shape({
  scan_duration: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Required field')
    .min(1, 'Minimum at least 1')
    .max(600, 'Allowed maximum is 600'),
  active_sleep: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Required field')
    .min(1, 'Minimum at least 1')
    .max(3600, 'Allowed maximum is 3600'),
  saving_sleep: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Required field')
    .min(300, 'Minimum at least 300')
    .max(7200, 'Allowed maximum is 7200'),
  accelerometer_force: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Required field')
    .min(10, 'Minimum at least 10')
    .max(4000, 'Allowed maximum is 4000'),
  antennas: yup.number().typeError('Please enter a valid number').required('Required field').min(1, 'Minimum at least one antenna'),
});

const Checkbox = styled(CheckboxMui).withConfig({
  shouldForwardProp: (prop) => !['$error'].includes(prop),
})<{
  $error?: boolean;
}>`
  ${({ $error, theme }) => $error && { color: theme.palette.error.main }};
`;

export const DeviceConfigurationForm = ({ onClose, open }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const formikRef = useRef<FormikProps<Values>>(null);
  const deviceDetails = useSelector(getDeviceDetailsById(id));
  const deviceConfiguration = useSelector(getDeviceConfiguration(deviceDetails?.result?.configuration_id));
  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      await dispatch(saveDeviceConfiguration({ deviceId: id, ...values })).unwrap();
      await dispatch(getDeviceById({ deviceId: id })).unwrap();

      onClose();
      // eslint-disable-next-line
    } catch (error: any) {
      setErrors(GetValidationErrors(error.response.status, navigate, error.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleChangeAntenna = (event: React.ChangeEvent<HTMLInputElement>, value) => {
    const antennas = event.target.dataset.antennas?.split('') || [];
    antennas[event.target.value] = value ? '1' : '0';

    formikRef.current?.setValues({
      ...formikRef.current?.values,
      antennas: parseInt(antennas.reverse().join(''), 2),
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={submit}
        initialValues={{
          scan_duration: deviceConfiguration?.result?.scan_duration,
          active_sleep: deviceConfiguration?.result?.active_sleep,
          saving_sleep: deviceConfiguration?.result?.saving_sleep,
          accelerometer_force: deviceConfiguration?.result?.accelerometer_force,
          antennas: deviceConfiguration?.result?.antennas,
          event_reporting: 0,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          // isValid,
          errors,
          isSubmitting,
          setFieldValue,
        }) => {
          const count = values.antennas?.toString(2).split('').reverse().join('') || '';
          const antennas = `${count}${'00'.slice(0, 2 - count.length)}`;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Edit Configuration</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      name="scan_duration"
                      label="Scan Duration"
                      value={values.scan_duration}
                      error={Boolean(touched.scan_duration && errors.scan_duration)}
                      fullWidth
                      helperText={touched.scan_duration && errors.scan_duration}
                      onBlur={handleBlur}
                      variant="outlined"
                      my={2}
                      required
                      onChange={(event) => setFieldValue('scan_duration', parseInt(event.target.value, 10))}
                    />
                    <TextField
                      name="active_sleep"
                      label="Active Sleep"
                      value={values.active_sleep}
                      error={Boolean(touched.active_sleep && errors.active_sleep)}
                      fullWidth
                      helperText={touched.active_sleep && errors.active_sleep}
                      onBlur={handleBlur}
                      variant="outlined"
                      my={2}
                      required
                      onChange={(event) => setFieldValue('active_sleep', parseInt(event.target.value, 10))}
                    />
                    <TextField
                      name="saving_sleep"
                      label="Standy Sleep"
                      value={values.saving_sleep}
                      error={Boolean(touched.saving_sleep && errors.saving_sleep)}
                      fullWidth
                      helperText={touched.saving_sleep && errors.saving_sleep}
                      onBlur={handleBlur}
                      variant="outlined"
                      my={2}
                      required
                      onChange={(event) => setFieldValue('saving_sleep', parseInt(event.target.value, 10))}
                    />
                    <TextField
                      name="accelerometer_force"
                      label="Accel. Force"
                      value={values.accelerometer_force}
                      error={Boolean(touched.accelerometer_force && errors.accelerometer_force)}
                      fullWidth
                      helperText={touched.accelerometer_force && errors.accelerometer_force}
                      onBlur={handleBlur}
                      variant="outlined"
                      my={2}
                      required
                      onChange={(event) => setFieldValue('accelerometer_force', parseInt(event.target.value, 10))}
                    />
                    <FormControl fullWidth sx={{ my: 2 }}>
                      <InputLabel id="event_reporting">Event Reporting</InputLabel>
                      <Select name="event_reporting" labelId="event_reporting" value={values.event_reporting} label="Event Reporting" onChange={handleChange}>
                        {[0, 1].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option ? 'Full' : 'Normal'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="caption" sx={{ ml: 4, mt: 2 }}>
                      Antennas{' '}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {antennas.split('').map((_, index) => (
                            <TableCell sx={{ p: 1, textAlign: 'center' }}>{index + 1}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {antennas.split('').map((value, index) => (
                            <TableCell sx={{ p: 1, textAlign: 'center' }}>
                              <Checkbox
                                value={index}
                                checked={value === '1'}
                                onChange={handleChangeAntenna}
                                inputProps={
                                  {
                                    'data-antennas': antennas,
                                  } as CheckBoxInputProps
                                }
                                {...(errors.antennas && { $error: true })}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                    {errors.antennas && (
                      <FormHelperText error sx={{ ml: 4 }}>
                        {errors.antennas}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ px: 6, pb: 6 }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
