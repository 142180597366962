import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type UsageFilterProps = {
  viewMode?: boolean;
  fetching: boolean;
  fullWidth?: boolean;
};

const usageTypes = {
  usage: 'Usage',
  'left behind': 'Left Behind',
  recovered: 'Recovered',
};

export default function UsageFilter({ viewMode, fetching, fullWidth }: UsageFilterProps): ReactElement | null {
  const [params, setURLParams] = useSearchParams();

  const currentOption = Object.keys(usageTypes).find((option) => option === params.usageType);

  return viewMode ? (
    <React.Fragment>
      {currentOption && (
        <Box component="span">
          Usage Type: <strong>{usageTypes[currentOption]}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="usage_label">Usage Type</InputLabel>
      <Select
        labelId="usage_label"
        value={params.usageType || -1}
        label="Usage Type"
        onChange={(event) => {
          setURLParams({
            newParams: { usageType: event.target.value === -1 ? undefined : event.target.value },
          });
        }}
        disabled={fetching}
        size="small"
      >
        <MenuItem value={-1}>All</MenuItem>
        {Object.keys(usageTypes).map((option) => (
          <MenuItem key={option} value={option}>
            {usageTypes[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
