import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryScanEvent } from '../../api/schema';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import prepareParams from '../../helpers/prepareParams';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination, { ExtendedPagination, PaginationControl } from '../../hooks/useTablePagination';
import { ScanEventsMappedType } from '../../redux/slices/scanEvents/scanEventsInterface';
import { getScanEvents, getScanEventsById, getScanEventsFetching } from '../../redux/slices/scanEvents/scanEventsSlice';
import { getSitesById, listSite } from '../../redux/slices/sites/sitesSlice';
import { useDispatch } from '../../redux/store';
import { Card, Typography } from '../../styled/components';
import SiteFilter from '../filters/SiteFilter';
import VehicleFilter from '../filters/VehicleFilter';

type ScanEventsProps = {
  scanEvents?: ScanEventsMappedType | undefined;
  noPayload?: boolean;
  withFilters?: boolean;
  type?: string;
  paginationControl?: PaginationControl;
  pagination?: ExtendedPagination;
};

export const ScanEvents: React.FC<ScanEventsProps> = ({
  scanEvents,
  noPayload,
  withFilters,
  type = 'customer',
  paginationControl,
  pagination,
}: ScanEventsProps) => {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const sites = useSelector(getSitesById({ siteOwner: type }));

  useEffect(() => {
    dispatch(listSite({ siteOwner: type }));
  }, [dispatch, withFilters, type]);

  const queryParams = Object.keys(params).reduce(
    (partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }),
    {},
  );

  const scanEventsData = useSelector(getScanEventsById(queryParams));

  const [paginationLocal, paginationToGetLocal, paginationControlLocal] = useTablePagination({ paginationDirty: scanEventsData?.pagination });

  useEffect(() => {
    if (withFilters && !scanEvents) {
      dispatch(
        getScanEvents({
          ...queryParams,
          ...paginationToGetLocal,
        }),
      );
    }
  }, [dispatch, queryParams, withFilters, paginationToGetLocal, scanEvents]);

  const periodFilter = usePeriodFilter({ params, setURLParams });

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const fetching = useSelector(getScanEventsFetching);

  const columns = useMemo(
    () => [
      ...(!noPayload
        ? [
            {
              Header: 'Payload',
              accessor: 'payloadIdMapped',
              Cell: ({ row }: { row: { original: QueryScanEvent & { payloadIdMapped: string } } }) => (
                <Link to={`/payloads/${row.original.payload_id}/details`}>{row.original.payloadIdMapped}</Link>
              ),
            },
          ]
        : []),
      {
        Header: 'Type',
        accessor: 'payload_type',
      },
      {
        Header: 'Vehicle',
        accessor: 'vehicle_name',
        Cell: ({ row }: { row: { original: QueryScanEvent } }) => (
          <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
        ),
        ...(withFilters && { Filter: VehicleFilter }),
      },
      {
        Header: 'Product & Identifier',
        accessor: 'productIdentifierMapped',
        sortBy: 'product_name',
        width: '20%',
        Cell: ({ row }: { row: { original: QueryScanEvent } }) => {
          const ellipsis = (
            <Grid container wrap="nowrap" spacing={2} sx={{ whiteSpace: 'nowrap', maxWidth: 300 }}>
              <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {row.original.product_name}
              </Grid>
              {row.original.identifier && (
                <>
                  <Grid item> — </Grid>
                  <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {row.original.identifier}
                  </Grid>
                </>
              )}
            </Grid>
          );
          return row.original.site_id ? <Link to={`/tools/items/${row.original.item_id}/details`}>{ellipsis}</Link> : ellipsis;
        },
      },
      {
        Header: 'Site',
        accessor: 'site_name',
        Cell: ({ row }: { row: { original: QueryScanEvent } }) => (
          <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.site_name}</Link>
        ),
        ...(withFilters && { Filter: SiteFilter, filterProps: { sites } }),
      },
      {
        Header: 'Site Visit',
        accessor: 'location_visit_id',
        Cell: ({ row }: { row: { original: QueryScanEvent } }) =>
          row.original.payload_type !== 'On Road' && <Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>Details</Link>,
        disableSortBy: true,
      },
      {
        Header: 'Event Type',
        accessor: 'event_type',
      },
      {
        Header: 'Scan Date',
        accessor: 'scan_date',
        Cell: ({ row }: { row: { original: QueryScanEvent } }) => <Typography>{tagsDate(row.original.processed_at)}</Typography>,
        ...(withFilters && { Filter: periodFilter }),
        ellipsis: true,
      },
    ],
    [noPayload, withFilters, periodFilter, sites],
  );

  return (
    <Card sx={{ pt: 2 }}>
      <Table
        columns={columns}
        data={scanEvents?.result ? scanEvents.result : scanEventsData?.result || []}
        loading={fetching}
        {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
        paginationControl={paginationControl || paginationControlLocal}
        pagination={pagination || paginationLocal}
        size="small"
        noGlobalFilter
      />
    </Card>
  );
};
