import { useSelector } from 'react-redux';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { ToolUsage } from './ToolUsage';
import { ToolUsageContainer } from './ToolUsageContainer';

export const ToolUsageHolder: React.FC = () => {
  const abilities = useSelector(getUserAbilities);

  return abilities.can('Container') ? <ToolUsageContainer withFilters /> : <ToolUsage withFilters />;
};
