import { Box, CardHeader, Chip, Grid, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import Skeleton from '../../component/skeleton/Skeleton';
import { adminGetItemById, adminItemFetching, getAdminItemById } from '../../redux/slices/admin/adminSlice';
import { useDispatch } from '../../redux/store';
import { CardFlex, GridCells } from '../../styled/components';

export const AdminToolDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const item = useSelector(getAdminItemById(id));
  const fetching = useSelector(adminItemFetching);
  const [clamped, setClamped] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(adminGetItemById(id));
    }
  }, [dispatch, id]);

  const ownerLink = useMemo(() => {
    const linkByName = {
      Vehicle: `/admin/organizations/vehicles/${item?.owner_id}/details`,
      Operator: `/admin/organizations/users/${item?.owner_id}/details`,
      Room: `/admin/organizations/rooms/${item?.owner_id}/details`,
    };

    return item?.owner_type_name && linkByName[item?.owner_type_name] ? (
      <Link to={linkByName[item?.owner_type_name]}>Assigned to {item?.owner_name}</Link>
    ) : (
      item?.owner_name
    );
  }, [item]);

  const handleReflow = ({ clamped }) => {
    setClamped(clamped);
  };

  const detailsData = [
    {
      label: 'Organization',
      value: <Link to={`/admin/organizations/organizations/${item?.organization_id}/details`}>{item?.organization_name}</Link>,
    },
    {
      label: 'Product',
      value: <Link to={`/admin/products/products/${item?.product_id}/details`}>{item?.product_name}</Link>,
    },
    {
      label: 'Manufacturer',
      value: <Link to={`/admin/products/manufacturers/${item?.manufacturer_id}/details`}>{item?.manufacturer_name}</Link>,
    },
    ...(!isEmpty(item?.tags) || fetching
      ? [
          {
            label: (
              <Tooltip
                title={
                  <>
                    {item?.tags?.map((tag) => (
                      <Box sx={{ mb: 1 }} key={tag.id}>
                        {tag.rfid}
                      </Box>
                    ))}
                  </>
                }
                arrow
                placement="top"
              >
                <Chip sx={{ mb: 2 }} label={`${item?.tags ? item?.tags.length : 0} RFID ${pluralize('Tag', item?.tags ? item?.tags.length : 0)}`} />
              </Tooltip>
            ),
            value: (
              <Tooltip title={clamped && item?.notes ? item?.notes : ''}>
                <span>
                  <LinesEllipsis text={item?.notes ? `Notes: ${item?.notes}` : ''} maxLine={3} onReflow={handleReflow} />
                </span>
              </Tooltip>
            ),
            ownValue: true,
          },
        ]
      : []),
    {
      label: 'Warehouse',
      value: ownerLink,
    },
    {
      label: 'Status',
      value: item?.active ? 'Is Active' : 'Is Disabled',
    },
    {
      label: 'Created',
      value: item?.created_at ? DateTime.fromISO(item?.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={item?.identifier} width={100} />} legend="Details" />
            <CardHeader title={<ListTypography data={detailsData} fetching={fetching} />} />
          </CardFlex>
        </Grid>
      </GridCells>
    </>
  );
};
