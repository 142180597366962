import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { updateLocationvisitJobsite } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { getAlternateSites, getListAlternateSites, mergeSite } from '../../redux/slices/sites/sitesSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { GetValidationErrors } from '../../tool/validation';

interface IProp {
  siteId?: string;
  onClose: (redirectURL?: string) => void;
  open: boolean;
  locationVisitId?: string;
}

interface Values {
  targetSiteId: string;
}

const schema = yup.object().shape({
  targetSiteId: yup.string().required('Required field'),
});

export const MergeSiteForm = ({ onClose, siteId, open, locationVisitId }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sites = useSelector(getAlternateSites(siteId));
  const formikRef = useRef<FormikProps<Values>>(null);
  const [showMergeWarning, setShowMergeWarning] = useState(false);

  useEffect(() => {
    if (siteId && open) {
      dispatch(getListAlternateSites(siteId));
    }
  }, [dispatch, siteId, open]);

  const submit = async (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      if (siteId && locationVisitId) {
        await dispatch(updateLocationvisitJobsite({ location_visit_id: locationVisitId, job_site_id: values.targetSiteId })).unwrap();
      } else if (siteId) {
        await dispatch(mergeSite({ siteId, targetSiteId: values.targetSiteId })).unwrap();
      }

      onClose(values.targetSiteId);
      // eslint-disable-next-line
    } catch (e: any) {
      setErrors(GetValidationErrors(e.response.status, navigate, e.response.data.messages));
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleMerge = () => {
    formikRef?.current?.submitForm();
    setShowMergeWarning(false);
  };

  const handleSave = () => {
    if (formikRef?.current?.values.targetSiteId) {
      setShowMergeWarning(true);
    } else {
      formikRef?.current?.setErrors({ targetSiteId: 'Required field' });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        {siteId && (
          <Formik
            innerRef={formikRef}
            validationSchema={schema}
            onSubmit={submit}
            initialValues={{
              targetSiteId: '',
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>{locationVisitId ? 'Swap Job Site for this Site Visit' : 'Merge this site into the following Job Site'}</DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        select
                        name="targetSiteId"
                        label="Job Site / Distance Away"
                        error={Boolean(errors.targetSiteId)}
                        fullWidth
                        value={values.targetSiteId}
                        helperText={errors.targetSiteId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        required
                      >
                        {(sites?.result &&
                          sites.result.map((site) => (
                            <MenuItem key={site.id} value={site.id}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                  {site.name}
                                </Grid>
                                <Grid item xs={6}>
                                  {site.distanceMapped} Miles
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))) ||
                          []}
                      </TextField>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 6, pb: 6 }}>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" disabled={isSubmitting} onClick={handleSave}>
                    {locationVisitId ? 'Swap' : 'Merge'}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        )}
      </Dialog>
      <Dialog open={showMergeWarning} onClose={() => setShowMergeWarning(false)}>
        <DialogTitle>
          {locationVisitId ? 'You are about to update the Job Site for this Site Visit?' : 'Merging job sites is a permanent operation. Are you sure?'}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setShowMergeWarning(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMerge} color="error" variant="contained">
            {locationVisitId ? 'Swap' : 'Merge'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
