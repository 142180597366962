import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { DateTime } from 'luxon';
import React, { ReactElement, SyntheticEvent, useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import { QueryDevice } from '../../api/schema';
import { assignDeviceToWarehouse } from '../../redux/slices/warehouses/warehousesSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';

interface IProp {
  onClose: (options?: { redirect?: string; edit?: boolean }) => void;
  open: boolean;
  fetching: boolean;
  devicesAvailable?: QueryDevice[];
  onChange: (identifier) => void;
  warehouseId?: string;
}

interface Values {
  device_id: string;
  warehouse_id: string;
}

const schema = yup.object().shape({
  device_id: yup.string().required('Required field'),
});

export const SwapIdentifierForm = ({ onClose, open, fetching, warehouseId, devicesAvailable, onChange }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Values>>(null);
  const [showSwapWarning, setShowSwapWarning] = useState(false);

  const submit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      setSubmitting(true);
      await dispatch(assignDeviceToWarehouse(values)).unwrap();

      onChange(devicesAvailable?.find((item) => item.device_id === values.device_id)?.identifier);
      onClose();
      // eslint-disable-next-line
      // @ts-ignore
    } catch (error: Error) {
      console.log('error', error); // eslint-disable-line
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleSwap = () => {
    formikRef?.current?.submitForm();
    setShowSwapWarning(false);
  };

  const handleSave = () => {
    if (formikRef?.current?.values.device_id) {
      setShowSwapWarning(true);
    } else {
      formikRef?.current?.setErrors({ device_id: 'Required field' });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        {!fetching && open && warehouseId && (
          <Formik
            innerRef={formikRef}
            validationSchema={schema}
            onSubmit={submit}
            initialValues={{
              device_id: '',
              warehouse_id: warehouseId,
            }}
          >
            {({ handleSubmit, values, handleChange, isSubmitting, errors }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <DialogTitle>Swap Identifier</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        select
                        name="device_id"
                        value={values.device_id}
                        label="Identifier / Hardware / Scanner / Created"
                        onChange={handleChange}
                        required
                        error={!!errors.device_id}
                        helperText={errors.device_id}
                      >
                        {devicesAvailable?.map((option) => (
                          <MenuItem key={option.identifier} value={option.device_id}>
                            <Grid container>
                              <Grid item xs={4}>
                                {option.identifier}
                              </Grid>
                              <Grid item xs={3}>
                                {option.hardware_version}
                              </Grid>
                              <Grid item xs={3}>
                                {option.scanner_running_build_tag}
                              </Grid>
                              <Grid item xs={2}>
                                {option.created_at ? DateTime.fromISO(option.created_at).toLocaleString(DateTime.DATE_MED) : '—'}
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </DialogContent>
                  <DialogActions sx={{ px: 6, pb: 6 }}>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained" disabled={isSubmitting} onClick={handleSave}>
                      Swap
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        )}
      </Dialog>
      <Dialog open={showSwapWarning} onClose={() => setShowSwapWarning(false)}>
        <DialogTitle>Are you sure you want to swap the device?</DialogTitle>

        <DialogActions>
          <Button onClick={() => setShowSwapWarning(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSwap} color="error" variant="contained">
            Swap
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
