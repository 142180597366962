import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { QueryLocationVisitSummaryMapped } from '../../redux/slices/locationVisits/locationVisitsInterface';
import { getLocationVisitsFetching } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { ChipLocation } from '../../styled/components';
import Table from '../table/Table';
import { LocationVisitsProps } from './interface';

const SpanStyled = styled.span`
  &.red {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

export const LocationVisits: React.FC<LocationVisitsProps> = ({
  items,
  title,
  noCustomer,
  paginationControl,
  pagination,
  dense,
  noUsed,
  noLeftBehind,
  withStockInstalled,
}: LocationVisitsProps): ReactElement => {
  const fetching = useSelector(getLocationVisitsFetching);
  const abilities = useSelector(getUserAbilities);

  const columns = useMemo(
    () => [
      {
        Header: 'Site Visit',
        accessor: 'periodMapped',
        width: '60%',
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped & { periodMapped: string } } }) => (
          <ChipLocation
            label={<Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>{row.original.periodMapped}</Link>}
            size="small"
            variant="outlined"
            status={row.original.tools_left_behind_cnt && row.original.tools_left_behind_cnt > 0 ? 'left behind' : 'transparent'}
            noEllipsis
          />
        ),
      },
      {
        Header: 'Vehicle',
        accessor: 'vehicle_name',
        width: 100,
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) => (
          <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
        ),
      },
      ...(!noCustomer
        ? [
            {
              Header: 'Customer - Location',
              accessor: 'customerLocationMapped',
              sortBy: 'customer_name',
              Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) =>
                row.original.site_id ? (
                  <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.customerLocationMapped}</Link>
                ) : (
                  row.original.customerLocationMapped
                ),
              ellipsis: true,
              width: '40%',
            },
          ]
        : []),
      ...(!noUsed
        ? [
            {
              Header: 'Used',
              accessor: 'tools_used_cnt',
              align: 'right',
              width: 50,
            },
          ]
        : []),
      ...(!noLeftBehind
        ? [
            {
              Header: 'Left Behind',
              accessor: 'items_left_behind_cnt',
              Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) => (
                <SpanStyled className={`${row.original.tools_left_behind_cnt && row.original.tools_left_behind_cnt > 0 && 'red'}`}>
                  {row.original.tools_left_behind_cnt}
                </SpanStyled>
              ),
              align: 'right',
              width: 50,
            },
          ]
        : []),
      ...(!withStockInstalled && abilities.can('Stock')
        ? [
            {
              Header: 'Installed',
              accessor: 'stock_installed_cnt',
              align: 'right',
              width: 50,
            },
          ]
        : []),
    ],
    [noCustomer, noLeftBehind, noUsed, withStockInstalled, abilities],
  );

  return (
    <Table
      columns={columns}
      data={items?.result || []}
      title={title}
      loading={fetching}
      paginationControl={paginationControl}
      pagination={pagination}
      {...(dense && { size: 'small', noGlobalFilter: true })}
    />
  );
};
