import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { ToolsList } from '../../component/tools/ToolsList';
import { UserForm } from '../../component/user/UserForm';
import { formatPhoneNumber } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  adminUserDelete,
  getAdminUserByUuid,
  getAdminUserDeleting,
  getAdminUserDetailsById,
  getAdminUserDetailsFetching,
} from '../../redux/slices/admin/adminSlice';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getProductToolsById, listProductTools } from '../../redux/slices/tools/toolsSlice';
import {
  getUserAbilities,
  getUserByUuid,
  getUserData,
  getUserDeleting,
  getUserDependencies,
  getUserDependenciesById,
  getUserDetailsById,
  getUserDetailsFetching,
  updateUser,
  userDelete,
  userDependenciesFetching,
} from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { UserDetailsProps } from './interface';

export const UserDetails: React.FC<UserDetailsProps> = ({ current, adminUser }: UserDetailsProps) => {
  const dispatch = useDispatch();

  const { id: userId, tab = 0 } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(getUserData);
  const id = current ? currentUser?.id : userId;
  const fetchingCommon = useSelector(getUserDetailsFetching);
  const fetchingAdmin = useSelector(getAdminUserDetailsFetching);
  const items = useSelector(getProductToolsById({ ownerId: id }));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: items.pagination });
  const userCommon = useSelector(getUserDetailsById(id));
  const userAdmin = useSelector(getAdminUserDetailsById(id));
  const imageKeys = useSelector(getImagesByOwnerId(id));
  const dependencies = useSelector(getUserDependenciesById(id));
  const dependenciesFetching = useSelector(userDependenciesFetching);
  const userDeleting = useSelector(adminUser ? getAdminUserDeleting : getUserDeleting);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');
  const [deleting, setDeleting] = useState(false);
  const tabValue = Number(tab);
  const user = adminUser ? userAdmin : userCommon;
  const fetching = adminUser ? fetchingAdmin : fetchingCommon;
  const abilities = useSelector(getUserAbilities);
  const [, , clearParams] = useSearchParams();

  useEffect(() => {
    if (id) {
      dispatch(listProductTools({ ownerId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  useEffect(() => {
    if (id) {
      dispatch(adminUser ? getAdminUserByUuid(id) : getUserByUuid(id));
    }
  }, [dispatch, id, adminUser]);

  useEffect(() => {
    if (id && abilities.can('edit')) {
      dispatch(getUserDependencies(id));
    }
  }, [dispatch, id, abilities]);

  useEffect(() => {
    if (id) {
      dispatch(listImagesByOwnerId(id));
    }
  }, [dispatch, id]);

  const handleCloseEditUser = (redirect?: string) => {
    // forceImageListUpdate((request) => request + 1);
    setShowEditUser(false);
    if (redirect && !current) {
      navigate(redirect);
    }
  };

  const handleEditClick = () => setShowEditUser(true);

  const handleCloseDelete = () => {
    setShowDeleteUser(false);
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          ...user,
          active: false,
        };
        await dispatch(updateUser(payload)).unwrap();
      } else if (id) {
        await dispatch(adminUser ? adminUserDelete(id) : userDelete(id)).unwrap();
      }
      navigate(adminUser ? `/admin/organizations/organizations/${user?.organization_id}/details` : '/company/users');
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  const disableDelete = fetching || dependenciesFetching;

  const handleChangeTab = (event, newValue) => {
    navigate(`/company/users/${id}/details/${newValue}${clearParams}`);
  };

  const details = [
    {
      label: 'Email',
      value: user?.email ? <a href={`mailto: ${user?.email}`}>{user?.email}</a> : '',
    },
    {
      label: 'Phone',
      value: formatPhoneNumber(user?.phone),
    },
    {
      label: 'Role',
      value: user?.roles?.split(',').join(', '),
    },
    {
      label: 'Status',
      value: user?.active ? 'Is Active' : 'Is Disabled',
    },
    {
      label: 'Created',
      value: user?.created_at ? DateTime.fromISO(user?.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex
              title={
                <Skeleton
                  randomWidth
                  fetching={fetching}
                  value={
                    <>
                      {user?.first_name} {user?.last_name}
                    </>
                  }
                  width={100}
                />
              }
              legend="Details"
            />
            <CardHeader
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  {abilities.can('edit') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                      <Button onClick={handleEditClick} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                        Edit
                      </Button>
                    </Grid>
                  )}
                  {abilities.can('delete') && !current && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                      <DependenciesButton
                        disableDelete={disableDelete}
                        dependencies={dependencies?.connected_record_count}
                        active={user?.active}
                        deleting={deleting}
                        name={user?.first_name}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              title={<ListTypography data={details} fetching={fetching} />}
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="User" legend="Image" />
            <MediaHolder className={clsx({ icon: imageKeys.length === 0 })} sx={{ p: 4 }}>
              {imageKeys.length > 0 ? (
                <CardMedia component="img" image={imageBase(imageKeys[0])} alt={`${user?.first_name} ${user?.last_name}`} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
      </GridCells>
      {!adminUser && (
        <Card mb={6}>
          <CardHeaderTabs
            title={
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                  <Tab label="Tools" />
                </Tabs>
              </Box>
            }
            action={
              <IconButton onClick={() => setPopupOpen('tabs')}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            }
            sx={{
              '& .MuiCardHeader-content': {
                width: 0,
              },
            }}
          />
          <CardContent sx={{ p: 0 }}>
            <TabPanel value={tabValue} index={0}>
              <ToolsList items={items} dense paginationControl={paginationControl} pagination={pagination} withAssignedTo withProduct withManufacturer />
            </TabPanel>
          </CardContent>
        </Card>
      )}

      <UserForm open={showEditUser} userID={id} onClose={handleCloseEditUser} adminUser={adminUser} imageKeys={imageKeys} />
      <Dialog open={showDeleteUser} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete this user?</DialogTitle>
        <DialogContent>
          <Typography variant="h2" component="div">
            {`${user?.first_name} ${user?.last_name}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary" disabled={userDeleting}>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained" disabled={userDeleting}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <InfoPopup title="Tab Details" subtitle="Displays the list of Tools assigned to the User." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Tools</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools currently assigned to the User.
          </CardContent>
        </Card>
      </InfoPopup>
    </>
  );
};
