import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { imageBase } from '../../api/client';
import toastOptions from '../../helpers/toastOptions';
import {
  getProductToolsById,
  getToolDetailsById,
  listProductTools,
  productFetching,
  productToolsFetching,
  setMissingReason,
} from '../../redux/slices/tools/toolsSlice';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import Table from '../table/Table';

interface IProp {
  onClose: (redirect?: string) => void;
  productToolId?: string;
  open: boolean;
  locationVisitId?: string;
  onSuccess?: VoidFunction;
}

export const MissingReasonDialog = ({ onClose, productToolId, open, locationVisitId, onSuccess }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const item = useSelector(getToolDetailsById(productToolId));
  const tools = useSelector(getProductToolsById({ locationVisitId }));
  const toolFetching = useSelector(productFetching);
  const toolsFetching = useSelector(productToolsFetching);
  const [reason, setReason] = useState<number | null>(1);
  const [itemIds, setItemIds] = useState<string[]>([]);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const user = useSelector(getUserData);

  const fetching = (!!productToolId && toolFetching) || (!!locationVisitId && toolsFetching);

  useEffect(() => {
    if (locationVisitId && open) {
      dispatch(listProductTools({ locationVisitId }));
    }
  }, [locationVisitId, open, dispatch]);

  useEffect(() => {
    if (open) {
      setReason(1);
    }
  }, [open]);

  const handleSetReason = (event: SyntheticEvent, reason: number | null) => {
    if (reason !== null) {
      setReason(reason);
    }
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'image_key',
        Header: 'Image',
        Cell: ({ row }: { row: { original: { image_key: string; product_name: string } } }) =>
          row.original.image_key && (
            <img src={`${imageBase(row.original.image_key)}?height=${isLgUp ? 36 : 25}`} key={row.original.image_key} alt={row.original.product_name} />
          ),
        disableSortBy: true,
        width: 50,
        style: { paddingTop: 2, paddingBottom: 2 },
      },

      {
        Header: 'Tool',
        accessor: 'identifier',
        width: '30%',
      },
      {
        Header: 'Product',
        accessor: 'product_name',
        ellipsis: true,
        width: '70%',
      },
      {
        Header: 'Current Reason',
        accessor: 'tracking_status',
        width: 150,
      },
    ],
    [isLgUp],
  );

  const data = useMemo(() => {
    const fillItem = (item) => ({
      item_id: item?.item_id,
      image_key: item?.image_key,
      identifier: item?.identifier,
      product_name: item?.product_name,
      tracking_status: item?.tracking_status,
    });
    return [...(productToolId && item ? [fillItem(item)] : []), ...(locationVisitId && tools?.result ? tools?.result.map((item) => fillItem(item)) : [])];
  }, [item, productToolId, locationVisitId, tools?.result]);

  const handleRowSelect = useCallback(
    (rows) => {
      setItemIds(Object.keys(rows).map((key) => data[key].item_id));
    },
    [data],
  );

  const handleSaveReason = useCallback(async () => {
    if (!isEmpty(itemIds) && reason !== null && user?.organization_id) {
      try {
        await dispatch(setMissingReason({ organization_id: user.organization_id, item_ids: itemIds, missing_reason_id: reason }));
        toast.success('Missing Reason was successfully set', toastOptions);
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }
  }, [dispatch, reason, itemIds, user?.organization_id, onClose, onSuccess]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      {(!productToolId || (productToolId && item)) && (
        <>
          <DialogTitle> Set missing reason</DialogTitle>
          <DialogContent>
            <ToggleButtonGroup value={reason} exclusive onChange={handleSetReason} sx={{ mb: 4 }}>
              <ToggleButton value={0}>None</ToggleButton>
              <ToggleButton value={1} color="warning">
                JobSite Tool
              </ToggleButton>
              <ToggleButton value={2} color="error">
                Lost
              </ToggleButton>
            </ToggleButtonGroup>

            <Table
              columns={columns}
              data={data}
              loading={fetching}
              size="small"
              withPagination={false}
              withSelect
              noGlobalFilter
              onRowsSelect={handleRowSelect}
            />
          </DialogContent>
          <DialogActions sx={{ px: 6, pb: 6 }}>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={isEmpty(itemIds) || reason === null} onClick={handleSaveReason}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
