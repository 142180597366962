import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { QueryPayloadSummary } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import prepareParams from '../../helpers/prepareParams';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getPayloadByWarehouseIdFetching, getPayloads, getPayloadsByWarehouse } from '../../redux/slices/payloads/payloadsSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { PayloadsShortProps } from './interface';

export const PayloadsShort: React.FC<PayloadsShortProps> = ({ query, disableSortBy = false, admin }: PayloadsShortProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params, setURLParams] = useSearchParams();

  useEffect(() => {
    if (isEmpty(params)) {
      setURLParams({
        newParams: {
          processedBegin: DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO() as string,
          processedEnd: DateTime.local().set({ hour: 23, minute: 59, second: 59, millisecond: 99 }).toISO() as string,
        },
      });
    }
  }, []); // eslint-disable-line

  const queryParams = useMemo(
    () =>
      Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), query),
    [params, query],
  );

  const payloads = useSelector(getPayloadsByWarehouse(queryParams));
  const fetching = useSelector(getPayloadByWarehouseIdFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: payloads.pagination });

  useEffect(() => {
    if (id && queryParams.processedBegin && queryParams.processedEnd) {
      dispatch(getPayloads({ ...queryParams, ...paginationToGet }));
    }
  }, [dispatch, id, queryParams, paginationToGet]);

  const periodFilter = usePeriodFilter({ params, setURLParams, maxRangeDays: 30, beginRequired: true, endRequired: true });

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'processed_at',
        Cell: ({ row }: { row: { original: QueryPayloadSummary } }) =>
          row.original.processed_at ? (
            <Link to={admin ? `/admin/organizations/devices/payloads/${row.original.payload_id}/details` : `/payloads/${row.original.payload_id}/details`}>
              {DateTime.fromISO(row.original.processed_at).toLocaleString(DateTime.DATETIME_MED)}
            </Link>
          ) : (
            ''
          ),
        disableSortBy,
        Filter: periodFilter,
      },

      {
        Header: 'Job Site Detected',
        accessor: 'site_name',
        disableSortBy,
      },
      {
        Header: 'Location Type',
        accessor: 'payload_type',
        disableSortBy,
      },
      {
        Header: 'X-Scan',
        accessor: 'xScanMapped',
        sortBy: 'is_cross_scan',
        width: 50,
        disableSortBy,
      },
      {
        Header: 'Items Count',
        accessor: 'items_count',
        disableSortBy,
      },
      {
        Header: 'Avg. Reads',
        accessor: 'average_reads',
        disableSortBy,
      },
    ],
    [disableSortBy, admin, periodFilter],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={payloads.result || []}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          size="small"
          noGlobalFilter
          onFiltersClear={handleFiltersClear}
          hideClearFilters
        />
      </Card>
    </>
  );
};
