import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ManufacturerFilter from '../../component/filters/ManufacturerFilter';
import UsageFilter from '../../component/filters/UsageFilter';
import VehicleFilter from '../../component/filters/VehicleFilter';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryLocationVisitItemsMapped } from '../../redux/slices/locationVisits/locationVisitsInterface';
import { getLocationVisitItems, getLocationVisitItemsFetching, locationVisitItems } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { listVehicles } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { LocationVisitsProps } from './interface';

const manufacturerFilter = { productTypeId: '1,3,4,6' };

export const ToolUsage: React.FC<LocationVisitsProps> = ({ withFilters }: LocationVisitsProps) => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params, setURLParams] = useSearchParams();
  const paramsToUse = useMemo(
    () =>
      Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {
        productTypeId: '1,3,4,5,6',
      }),
    [params],
  );

  const locations = useSelector(locationVisitItems(paramsToUse));
  const fetching = useSelector(getLocationVisitItemsFetching);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: locations.pagination });
  const locationsExport = useSelector(locationVisitItems({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(
      getLocationVisitItems({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      getLocationVisitItems({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  useEffect(() => {
    dispatch(listVehicles({}));
  }, [dispatch]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const PeriodFilter = usePeriodFilter({ params, setURLParams });

  const columns = useMemo(
    () => [
      {
        Header: 'Site Visit',
        accessor: 'periodMapped',
        sortBy: 'first_visit',
        width: '20%',
        Cell: ({ row }: { row: { original: QueryLocationVisitItemsMapped & { periodMapped: string } } }) => (
          <Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>{row.original.periodMapped}</Link>
        ),
        ...(withFilters && { Filter: PeriodFilter }),
        ellipsis: true,
      },
      {
        Header: 'Vehicle',
        accessor: 'vehicle_name',
        width: 70,
        Cell: ({ row }: { row: { original: QueryLocationVisitItemsMapped } }) => (
          <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
        ),
      },

      {
        Header: 'Customer & Site Name',
        accessor: 'customerSiteNameMapped',
        sortBy: 'customer_name',
        Cell: ({ row }: { row: { original: QueryLocationVisitItemsMapped } }) =>
          row.original.site_id ? (
            <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.customerSiteNameMapped}</Link>
          ) : (
            row.original.customerSiteNameMapped
          ),
        ellipsis: true,
        width: '20%',
      },
      {
        Header: 'Product & Identifier',
        accessor: 'productIdentifierMapped',
        sortBy: 'product_name',
        Cell: ({ row }: { row: { original: QueryLocationVisitItemsMapped } }) => {
          const ellipsis = (
            <Grid container wrap="nowrap" spacing={2} sx={{ whiteSpace: 'nowrap', maxWidth: 300 }}>
              <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {row.original.product_name}
              </Grid>
              {row.original.identifier && (
                <>
                  <Grid item> — </Grid>
                  <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {row.original.identifier}
                  </Grid>
                </>
              )}
            </Grid>
          );
          return row.original.site_id ? <Link to={`/tools/items/${row.original.item_id}/details`}>{ellipsis}</Link> : ellipsis;
        },
        width: '20%',
        ...(withFilters && { Filter: ManufacturerFilter, filterProps: { filters: manufacturerFilter } }),
      },
      {
        Header: 'Removed At',
        accessor: 'removed_at',
        Cell: ({ row }: { row: { original: QueryLocationVisitItemsMapped } }) => tagsDate(row.original.removed_at),
        ...(withFilters && { Filter: VehicleFilter }),
      },
      {
        Header: 'Usage Type',
        accessor: 'usage_type',
        width: 150,
        ...(withFilters && { Filter: UsageFilter, filterProps: { fetching } }),
      },
    ],
    [withFilters, PeriodFilter, fetching],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={locations.result}
          dataExport={locationsExport?.result}
          loading={fetching}
          {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
          paginationControl={paginationControl}
          pagination={pagination}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
    </>
  );
};
