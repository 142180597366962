import { Loop as LoopIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  AppBar as MuiAppBar,
  FormControl as MuiFormControl,
  OutlinedInput,
  Paper,
  Tab,
  TabProps,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash';
import { KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { usePageVisibility } from 'react-page-visibility';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { epcClient } from '../../api/client';
import { tagsDate } from '../../helpers/dataHelper';
import toastOptions from '../../helpers/toastOptions';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useInterval from '../../hooks/useInterval';
import useSearchParams from '../../hooks/useSearchParams';
import { addSearchItem, getLastRefresh, getSearchHistory, getSidebarCollapsed, setLastRefresh, setSidebarCollapsed } from '../../redux/slices/app/appSlice';
import { clearSearchResults, getSearchCompany, getSearchResults, getSearchResultsFetching } from '../../redux/slices/search/searchSlice';
import { getMenuByAbility, getRoutesByAbility, getUserData, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Route } from '../../routes';
import QrScan from '../qrScan/QrScan';
import Skeleton from '../skeleton/Skeleton';
import NavbarUserDropdown from './NavbarUserDropdown';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;
const InputAdornmentEnd = styled(InputAdornment).withConfig({
  shouldForwardProp: (prop) => !['open'].includes(prop),
})<{
  open?: boolean;
}>`
  transition: all ease-in-out 0.3s;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    opacity: ${({ open }) => (open ? 1 : 0)};
  }
`;

const FormControl = styled(MuiFormControl).withConfig({
  shouldForwardProp: (prop) => !['open'].includes(prop),
})<{
  open?: boolean;
}>`
  transition: all ease-in-out 0.3s;

  & .MuiInputBase-root {
    justify-content: space-between;
  }
  & .MuiInputAdornment-root {
    margin-left: 0;
  }
  & input {
    transition: all ease-in-out 0.3s;
  }
  & fieldset {
    transition: opacity ease-in-out 0.3s;
  }
  ${({ open }) =>
    open
      ? css`
          width: 200px;
        `
      : css`
          width: 42px;
          & input {
            width: 0;
            padding-left: 0;
          }
          & fieldset {
            opacity: 0;
          }
        `}
`;

type NavbarProps = {
  onDrawerToggle: VoidFunction;
};

const BoxEllipsis = styled(Box)`
  display: flex;
  > span {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    white-space: nowrap;
  }
`;

const hrefByCategory = {
  Items: (id: string) => (id ? `/tools/items/${id}/details` : '/tools/items'),
  Products: (id: string) => (id ? `/tools/products/${id}/details` : '/tools/products'),
  Manufacturers: (id: string) => (id ? `/tools/manufacturers/${id}/details` : '/tools/manufacturers'),
  Customers: (id: string) => (id ? `/customers/customers/${id}/details` : '/customers'),
  'Job Sites': (id: string) => (id ? `/customers/job-sites/${id}/details` : '/customers/job-sites'),
  Vehicles: (id: string) => (id ? `/company/vehicles/${id}/details` : '/company/vehicles'),
  Users: (id: string) => (id ? `/company/users/${id}/details` : '/company/users'),
  'Site Visits': (id: string) => (id ? `/activity/site-visits/${id}/details` : '/activity/site-visits'),
  'Stock Available': (id: string) => (id ? `/stock/items/${id}/details` : '/stock/items'),
  'Stock Installed (30d)': (id: string) => (id ? `/stock/items/${id}/details` : '/activity/stock-installed?recent=true'),
  'Stock Installed (>30d)': (id: string) => (id ? `/stock/items/${id}/details` : '/activity/stock-installed?recent=false'),
  'Tool Products': (id: string) => (id ? `/tools/products/${id}/details` : '/tools/products'),
  'Stock Products': (id: string) => (id ? `/stock/products/${id}/details` : '/stock/products'),
};

const hrefAdminByCategory = {
  'Tool Products': (id: string) => (id ? `/admin/products/products/${id}/details` : '/admin/products/products'),
  Manufacturers: (id: string) => (id ? `/admin/products/manufacturers/${id}/details` : '/admin/products/manufacturers'),
  'Admin Items': (id: string) => (id ? `/admin/items/${id}/details` : ''),
  Devices: (id: string) => (id ? `/admin/organizations/devices/${id}/details` : ''),
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }: NavbarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserData);
  const lastRefresh = useSelector(getLastRefresh);
  const theme = useTheme();
  const searchResultsFetching = useSelector(getSearchResultsFetching);
  const searchResults = useSelector(getSearchResults);
  const smLgUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isSmDn = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const outlet = useOutlet();
  const [searchIteration, setSearchIteration] = useState(false);
  const [searchForceOpen, setSearchForceOpen] = useState(smLgUp);
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const [modals, toggleModal] = useBooleanKeys({ showQrDialog: false });
  const isVisible = usePageVisibility();
  const lastRefreshPrevRef = useRef<string | undefined>();
  const lastRefreshRef = useRef<string | undefined>();
  const menuItems = useSelector(getMenuByAbility);
  const urlParams = useParams();
  const route = useCurrentRoute();
  const location = useLocation();
  const { refresh, title: currentTitle } = route?.route ?? {};
  const routes = useSelector(getRoutesByAbility);
  const parentRoute = matchRoutes(routes, location.pathname)?.[0].route as Route | undefined;
  const isAdminUser = useSelector(isAdminUserSelector);
  const [searchByPath, setSearchByPath] = useState<{ [key: string]: string }>({});
  const searchHistory = useSelector(getSearchHistory);

  const title = parentRoute?.title || currentTitle;

  const currentPath = outlet?.props?.children?.props?.routeContext?.matches.reduce(
    (partPath, item) => (partPath ? `${partPath}${item.route.path ? `/${item.route.path.split('/*').join('')}` : ''}` : item.route.path.split('/*').join('')),
    '',
  );

  const siblings = menuItems.reduce(
    (partSiblings, group) => {
      const groupSiblings = group.pages.reduce(
        (partPages, page) => {
          const path = currentPath?.split('/:')[0] || currentPath;
          const inChildren = page.children?.find((child) => [child.href.split('?')[0], ...(child.hrefAlias || [])].find((item) => item.indexOf(path) === 0));
          return inChildren
            ? page.children
                .filter((child) => {
                  const active = [child.href.split('?')[0], ...(child.hrefAlias || [])].includes(path);
                  return !child.hidden && (!child.activeOnly || (active && child.activeOnly));
                })
                .map((child) => ({
                  href: Object.keys(urlParams).reduce((partHref, param) => partHref.split(`/:${param}`).join(`/${urlParams[param]}`), child.href),
                  title: child.title,
                  active: !![child.href.split('?')[0], ...(child.hrefAlias || [])].find((item) => item.indexOf(path) === 0),
                  sx: child.sx,
                }))
            : partPages;
        },
        [] as { href: string; title: string; active: boolean; sx?: TabProps['sx'] }[],
      );
      return isEmpty(groupSiblings) ? partSiblings : groupSiblings;
    },
    [] as { href: string; title: string; active: boolean; sx?: TabProps['sx'] }[],
  );

  useEffect(() => {
    if (isVisible && lastRefreshPrevRef.current && lastRefreshPrevRef.current !== lastRefreshRef.current && refresh) {
      dispatch(setLastRefresh(new Date().toISOString()));
    }
    if (!isVisible) {
      lastRefreshPrevRef.current = lastRefreshRef.current;
    }
  }, [dispatch, isVisible, refresh]);

  useEffect(() => {
    lastRefreshRef.current = lastRefresh;
  }, [lastRefresh]);

  const handleClickAway = () => {
    setShowSearchResults(false);
    if (!smLgUp) {
      setSearchForceOpen(false);
    }
  };

  const { resetInterval } = useInterval(
    () => {
      if (refresh) {
        dispatch(setLastRefresh(new Date().toISOString()));
      }
    },
    3 * 60 * 1000,
    true,
  );

  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  const initials = useMemo(() => {
    return user ? `${user?.first_name?.split('')[0]}${user?.last_name?.split('')[0]}` : 'u';
  }, [user]);

  const handleLoadData = useCallback(() => {
    dispatch(setLastRefresh(new Date().toISOString()));
  }, [dispatch]);

  useEffect(() => {
    resetInterval();
  }, [lastRefresh, resetInterval]);

  const handleToggleSidebar = useCallback(() => {
    dispatch(setSidebarCollapsed(false));
  }, [dispatch]);

  const handleGetSearchCompany = useCallback(() => {
    setShowSearchResults(true);
    if (!searchForceOpen) {
      setSearchForceOpen(true);
    }

    if (searchInputRef?.current?.value) {
      const valueToUse = searchInputRef?.current.value
        .split('/')
        .reverse()[0]
        .replace(/^0[xX]/, '');
      dispatch(getSearchCompany(valueToUse));
      dispatch(addSearchItem(valueToUse));
      if (searchInputRef?.current?.value && searchInputRef?.current?.value !== valueToUse) {
        searchInputRef.current.value = valueToUse;
      }
      setSearchIteration(true);
    }
  }, [dispatch, searchForceOpen]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      setSearchIteration(false);
      setShowSearchResults(false);
      setSearchForceOpen(false);
      dispatch(clearSearchResults());
    }
  }, [pathname, dispatch]);

  const handleCategoryClick = useCallback(
    (event: SyntheticEvent) => {
      const {
        dataset: { category, id, direct },
      } = event.currentTarget as HTMLDivElement;

      const hrefChecker = isAdminUser ? hrefAdminByCategory : hrefByCategory;
      const targetHref = category && hrefChecker[category] ? hrefChecker[category](direct === 'true' ? id : '') : null;
      if (category && targetHref && searchInputRef?.current?.value) {
        if (pathname !== targetHref && direct === 'true' && id) {
          navigate(targetHref);
        } else if (direct !== 'true' && (pathname !== targetHref || params.search !== searchInputRef.current.value)) {
          navigate(`${targetHref}${targetHref.indexOf('?') > 0 ? '&' : '?'}search=${searchInputRef.current.value}`);
        }

        searchInputRef.current.value = '';
        setSearchIteration(false);
        setShowSearchResults(false);
        setSearchForceOpen(false);
        dispatch(clearSearchResults());
      }
    },
    [navigate, dispatch, pathname, params.search, isAdminUser],
  );

  const handleHistoryClick = (event: SyntheticEvent) => {
    const {
      dataset: { id },
    } = event.currentTarget as HTMLDivElement;
    if (id && searchInputRef.current) {
      searchInputRef.current.value = id;
      searchInputRef.current.focus();
      setShowSearchResults(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      handleGetSearchCompany();
    }
  };

  const handleOnSearchChange = () => {
    dispatch(clearSearchResults());
    setSearchIteration(false);
  };

  const checkScanResult = async (decodedText) => {
    try {
      const response = await epcClient.validateEpc(decodedText);
      if (response.data.result || decodedText.length < 12) {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
          searchInputRef.current.value = decodedText;
          handleGetSearchCompany();
        }
      } else {
        toast.error(`"${decodedText}" is Invalid EPC.`, toastOptions);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const handleQrScanClose = () => {
    toggleModal('showQrDialog');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleQrScanOpen = () => {
    toggleModal('showQrDialog');
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  const getSearchString = (search?: string) => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('search');
    return searchParams.toString();
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const newValueString = newValue.toString();
    const search = getSearchString(searchByPath[newValueString]);
    navigate(newValueString.indexOf('?') >= 0 ? newValueString : { pathname: newValueString, ...(search && { search }) });
  };

  const handleTabClick = (event: React.SyntheticEvent) => {
    const { classList, dataset } = event.target as HTMLDivElement;
    if (Array.from(classList).includes('Mui-selected') && pathname != dataset.value && dataset.value) {
      const search = getSearchString(searchByPath[dataset.value]);
      navigate(dataset.value.indexOf('?') >= 0 ? dataset.value : { pathname: dataset.value, ...(search && { search }) });
    }
  };

  const activeTab = siblings?.find((item) => item.active);

  useEffect(() => {
    setSearchByPath((locations) => ({ ...locations, [location.pathname]: location.search.replace(/^(\?)/, '') }));
  }, [location]);

  return (
    <>
      <Helmet title={parentRoute?.title ? `${parentRoute?.title} / ${currentTitle}` : currentTitle} />
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" wrap="nowrap">
            {isSmDn && (
              <Hidden mdUp={!sidebarCollapsed}>
                <Grid item>
                  <IconButton color="inherit" aria-label="Open drawer" onClick={sidebarCollapsed ? handleToggleSidebar : onDrawerToggle} size="large">
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            )}
            <Grid item pl={smLgUp ? 9 : 2} xs>
              {title && (
                <Typography variant={smLgUp ? 'h3' : 'h4'} component="div" sx={{ mt: 1 }} color={theme.palette.common.black}>
                  <BoxEllipsis>
                    <span>{title}</span>
                  </BoxEllipsis>
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ position: smLgUp ? 'inherit' : 'relative', height: 42, minWidth: 40 }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <FormControl sx={{ m: 1, position: smLgUp ? 'relative' : 'absolute', right: 0, top: 0 }} variant="outlined" open={smLgUp || searchForceOpen}>
                  <OutlinedInput
                    inputRef={searchInputRef}
                    type="text"
                    size="small"
                    onKeyDown={handleKeyDown}
                    onChange={handleOnSearchChange}
                    onFocus={() => setShowSearchResults(true)}
                    sx={{ background: (theme) => theme.palette.background.paper, paddingLeft: 0 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleGetSearchCompany} edge="end">
                          {searchResultsFetching ? <CircularProgress size={22} /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornmentEnd position="end" open={smLgUp || searchForceOpen}>
                        <IconButton onClick={handleQrScanOpen} edge="end">
                          <Box component="i" className="tags-icon-barcode" sx={{ lineHeight: 1 }} />
                        </IconButton>
                      </InputAdornmentEnd>
                    }
                  />

                  {showSearchResults && (
                    <Paper
                      ref={searchResultsRef}
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: 200,
                        cursor: 'default',
                        zIndex: (theme) => theme.zIndex.mobileStepper,
                      }}
                    >
                      {searchIteration && (
                        <Box>
                          {searchResultsFetching && (
                            <Box sx={{ py: 3, px: 4 }}>
                              <Skeleton />
                            </Box>
                          )}
                          {!searchResultsFetching && isEmpty(searchResults) && <Box sx={{ py: 3, px: 4 }}>No Results</Box>}
                          {!searchResultsFetching && !isEmpty(searchResults) && (
                            <MenuList>
                              {searchResults?.map((item) => (
                                <MenuItem
                                  key={item.category}
                                  onClick={handleCategoryClick}
                                  data-category={item.category}
                                  data-id={item.id}
                                  data-direct={item.direct}
                                >
                                  <Grid container>
                                    <Grid item xs={3}>
                                      {item.count}
                                    </Grid>
                                    <Grid item xs={9}>
                                      {
                                        // TODO Remove replace after API update
                                        item.category === 'Items' ? 'Tools' : item.category
                                      }
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              ))}
                            </MenuList>
                          )}
                        </Box>
                      )}
                      {!isEmpty(searchHistory) && !searchIteration && (
                        <MenuList>
                          {searchHistory?.map((item) => (
                            <MenuItem key={item} onClick={handleHistoryClick} data-id={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </MenuList>
                      )}
                    </Paper>
                  )}
                </FormControl>
              </ClickAwayListener>
            </Grid>
            <Grid item pr={smLgUp ? 8 : 5} xs>
              <Grid container alignItems="center" spacing={smLgUp ? 6 : 2} wrap="nowrap" justifyContent="flex-end">
                <Grid item pr={smLgUp ? 2 : 0}>
                  <Button sx={{ whiteSpace: 'nowrap' }} startIcon={<LoopIcon />} size={smLgUp ? 'large' : 'small'} onClick={handleLoadData} variant="contained">
                    {tagsDate(lastRefresh, 'TIME_WITH_SECONDS')}
                  </Button>
                </Grid>
                {smLgUp && (
                  <Grid item pl={2}>
                    <NavbarUserDropdown initials={initials} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {!isEmpty(siblings) && (
          <Box pl={smLgUp ? 8 : 4} mt={-4}>
            <Tabs variant="scrollable" value={activeTab?.href || ''} onChange={handleChangeTab}>
              {siblings?.map((item) => (
                <Tab key={item.href} label={item.title} sx={item.sx} value={item.href} data-value={item.href} onClick={handleTabClick} />
              ))}
            </Tabs>
          </Box>
        )}
      </AppBar>
      <QrScan open={modals.showQrDialog} onClose={handleQrScanClose} checkScanResult={checkScanResult} />
    </>
  );
};

export default Navbar;
